@tailwind base;
@tailwind components;
@tailwind utilities;

.status-pending {
    color: yellow;
}

.status-approved {
    color: green;
}

.status-rejected {
    color: red;
}